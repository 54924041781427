import React, { useState } from 'react'
import { Form, Input, message } from 'antd'
import axios from 'axios'
import { strapiURL } from '../../config'
const ResetPasswordForm = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const sendEmail = values => {
    setLoading(true)
    axios
      .post(strapiURL + '/auth/forgot-password', {
        email: values?.email,
      })
      .then(res => {
        form.resetFields()
        message.success(
          'Your request has been received, if your email address is found you will receive directions on how to reset your password.'
        )
        setLoading(false)
      })
      .catch(err => {
        form.resetFields()
        message.success(
          'Your request has been received, if your email address is found you will receive directions on how to reset your password.'
        )
        setLoading(false)
      })
  }

  return (
    <>
      <div className="h-[10vh] flex items-center pl-6 sm:pl-0">
        <div className="container mx-auto flex justify-between items-center">
          <div>
            <a aria-current="page" className="" href="/">
              <img
                src="/images/logo.png"
                className="h-4.5 lg:h-10 mb-0"
                alt="Medreps Logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full px-6 lg:px-0 lg:w-4/12 mx-auto flex flex-col items-center justify-center h-[80vh]">
        <h1 className="title font-normal font-playfair text-center text-[26px] lg:text-3xl">
          Forgot Password
        </h1>
        <p className="font-sans text-center mt-3.5 lg:mt-2">
          Please enter your email address below and we will send you a
          confirmation email to reset your password.
        </p>
        <Form
          className="flex flex-col items-center w-full mt-9 lg:mt-10"
          layout="vertical"
          onFinish={sendEmail}
          form={form}
        >
          <div className="w-full">
            <Form.Item
              className="theme-form-item w-full"
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Enter Email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Enter Email"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none w-full"
              />
            </Form.Item>
          </div>

          <button className="bg-voyage py-3 px-6 font-sans text-white text-xs font-bold rounded mx-auto">
            {loading ? 'Loading...' : 'Submit'}
          </button>
        </Form>
      </div>
      <div className="h-[10vh]">
        <p className="text-center w-full font-sans text-[13px]">
          2000-2022 MedReps. All rights reserved.
        </p>
      </div>
    </>
  )
}
export default ResetPasswordForm
