export const strapiURL =
  process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'

export const auth0Roles = {
  Member: {
    id: process.env.GATSBY_ROLE_MEMBER || 'rol_OkVdVEN5VQKjf68X',
  },
  Employer: {
    id: process.env.GATSBY_ROLE_EMPLOYER_ADMIN || 'rol_gb9dBESpMsvDfr6Q',
  },
  NamedUser: {
    id: process.env.GATSBY_ROLE_EMPLOYER_NAMED_USER || 'rol_ATrbyGkHHMoICeei',
  },
}

export const GOOGLE_API_KEY =
  process.env.GOOGLE_API_KEY || 'AIzaSyABGfSboCejfU8qrWjZcp2K-IyvPRFl3Dg'

export const isBrowser = () => typeof window !== 'undefined'

export const getAdManagerConfig = path => {
  if ((path || '').includes('/employer-dashboard/account/')) {
    return {
      find: true,
      id: 'div-gpt-ad-1659626628324-0',
      adUnit: '/22694030668/MedReps_AccountManagement',
      desktopSize: [728, 90],
    }
  }

  if ((path || '').includes('/medical-sales-careers/')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927594882-0',
      adUnit: '/22694030668/MedReps_Article',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  if ((path || '').includes('/job-search/')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927614489-0',
      adUnit: '22694030668/MedReps_JobSearch',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  if ((path || '').includes('/job-detail/')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927658341-0',
      adUnit: '/22694030668/MedReps_JobDetail',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  // Join Now - Need to replace correct routing
  if ((path || '').includes('/join-now/')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927705487-0',
      adUnit: '/22694030668/MedReps_JoinNow',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  if ((path || '') === '/') {
    return {
      find: true,
      adUnit: '/22694030668/MedReps_Home',
      id: 'div-gpt-ad-1646927733864-0',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  if ((path || '') === '/profile') {
    return {
      find: true,
      adUnit: '/22694030668/MedReps_CandidateProfile',
      id: 'div-gpt-ad-1646927782573-0',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  if ((path || '').includes('/employer/signup')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927765757-0',
      adUnit: '/22694030668/MedReps_Employer',
      desktopSize: [728, 90],
    }
  }

  if ((path || '').includes('/member-dashboard/')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927515567-0',
      adUnit: '/22694030668/MedReps_AccountManagement',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  if ((path || '').includes('/salary')) {
    return {
      find: true,
      id: 'div-gpt-ad-1646927800751-0',
      adUnit: '/22694030668/MedReps_SalaryInformation',
      desktopSize: [728, 90],
      mobileSize: [300, 50],
    }
  }

  return {
    find: false,
  }
}
